import React, { useEffect, useState } from "react";
import { Navbar, Footer, Skills, Analytics } from "../components";

//import Hero from '../components/Hero';
//import Start from '../components/Start';
//import ContactForm from '../components/form/ContactForm';

//import Newsletter from '../components/Newsletter';
//import PaymentPlanCards from '../components/PaymentPlanCards';
import Meta from "../components/Meta"; //<Meta title="Star soft design - home" />
//import { PROJECTS } from '../../constants/projects';
//import { projectsData } from '../data/projectsData';
import ProductCard from "../components/ProductCard";
import foodApi from "../api/foodApi";
//import DOMPurify from "dompurify";
import Loading from "../components/loading";
//import "react-quill/dist/quill.snow.css";

const Product = () => {
  const [pag, setPag] = useState(null);
  useEffect(() => {
   // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const cucu = foodApi.getFoods("/products/list").then((x) => setPag(x));
    console.log("cucu", cucu);
  }, []);

  return (
    <>
      <Meta title="Pruducts" />
      <div className="w-full h-screen relative overflow-hidden">
        {/* Background image */}
        <div className="h-full bg-[url('assets/tech-bg.webp')] bg-cover"></div>
        <div className="absolute top-0 w-full">
          <Navbar transparent />

          <div className="text-white">
            <div className="max-w-[800px] mt-[-46px] w-full h-screen mx-auto text-center flex flex-col justify-center">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                  <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                    <span className="mb-2 block mx-2 lg:text-5xl text-2xl font-semibold text-green-500">
                    HERE ARE SOME OF OUR CUSTOM PRODUCTS WE OFFER
                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* First section */}
      <div
        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
        style={{ height: "70px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto px-4">
      {!pag && <Loading />}
      
        <div className="-mx-4 flex flex-wrap">
        {pag && (
          <>
          {pag.map((props) => (
            <ProductCard key={props.id} {...props} />
          ))}
          </>
        )}
          <div
            className="w-full px-4 md:w-1/2 xl:w-1/3"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <div className="relative mb-12">
              <div className="overflow-hidden rounded-lg">
                <img
                  src="assets/images/portfolio/portfolio-01/image-02.jpg"
                  alt="portfolio"
                  className="w-full"
                />
              </div>
              <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-white py-9 px-3 text-center shadow-lg">
                <span className="mb-2 block text-sm font-semibold text-primary">
                  Marketing
                </span>
                <h3 className="mb-4 text-xl font-bold text-dark">
                  Best Marketing tips
                </h3>
                <a
                  href="#"
                  className="inline-block rounded-md border py-3 px-7 text-sm font-semibold text-body-color transition hover:border-primary hover:bg-blue-600 hover:text-white"
                >
                  View Details
                </a>
              </div>
            </div>
          </div>

          <div
            className="w-full px-4 md:w-1/2 xl:w-1/3"
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="600"
          >
            <div className="relative mb-12">
              <div className="overflow-hidden rounded-lg">
                <img
                  src="assets/images/portfolio/portfolio-01/image-03.jpg"
                  alt="portfolio"
                  className="w-full"
                />
              </div>
              <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-white py-9 px-3 text-center shadow-lg">
                <span className="mb-2 block text-sm font-semibold text-primary">
                  Development
                </span>
                <h3 className="mb-4 text-xl font-bold text-dark">
                  Web Design Trend
                </h3>
                <a
                  href="#"
                  className="inline-block rounded-md border py-3 px-7 text-sm font-semibold text-body-color transition hover:border-primary hover:bg-blue-600 hover:text-white"
                >
                  View Details
                </a>
              </div>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 xl:w-1/3">
            <div className="relative mb-12">
              <div className="overflow-hidden rounded-lg">
                <img
                  src="assets/images/portfolio/portfolio-01/image-04.jpg"
                  alt="portfolio"
                  className="w-full"
                />
              </div>
              <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-white py-9 px-3 text-center shadow-lg">
                <span className="mb-2 block text-sm font-semibold text-primary">
                  Design
                </span>
                <h3 className="mb-4 text-xl font-bold text-dark">
                  Logo Card Design
                </h3>
                <a
                  href="#"
                  className="inline-block rounded-md border py-3 px-7 text-sm font-semibold text-body-color transition hover:border-primary hover:bg-blue-600 hover:text-white"
                >
                  View Details
                </a>
              </div>
            </div>
          </div>
        </div>
         )}
      </div>

      {/* Final section */}
    </>
  );
};

export default Product;
