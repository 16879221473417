import React, { useEffect, useState } from "react";
//import { Navbar, Footer, Skills, Analytics } from "../components";
import { Navbar, Footer } from "../components";
import { useParams } from "react-router-dom";
//import Hero from '../components/Hero';
//import Start from '../components/Start';
//import ContactForm from '../components/form/ContactForm';

//import Newsletter from '../components/Newsletter';
//import PaymentPlanCards from '../components/PaymentPlanCards';
import Meta from "../components/Meta"; //<Meta title="Star soft design - home" />
//import { PROJECTS } from '../../constants/projects';
///import { projectsData } from '../data/projectsData';
//import ProjectCard from '../components/ProjectCard';
import foodApi from "../api/foodApi";
import DOMPurify from "dompurify";
import Loading from "../components/loading";
import "react-quill/dist/quill.snow.css";

const Pages = () => {
  const routeParams = useParams();
  const [pag, setPag] = useState(null);
  /*useEffect(() => {
    const cucu = (async () => {
     const cuc = await foodApi.getFoods("/pages/slug/" + routeParams.id)
      .then((x) => setPag(x.data));
      console.log(cuc);
    });
    console.log(cucu);
  }, []);*/
  /*
  useEffect(() => {


    console.log("routeParams id", routeParams.id);
      const cucu = foodApi.getFoods("/pages/slug/" + routeParams.id)
      .then((x) => setPag(x.data));
      console.log("cucu", cucu);
    }); */

  useEffect(() => {
   //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const cucu = foodApi
      .getFoods("/pages/slug/" + routeParams.id)
      .then((x) => setPag(x.data));
    console.log("cucu", cucu);
  }, []);
  //	const classes = useStyles();

  /* useEffect(() => {


  console.log("routeParams id", routeParams.id);
    const cucu = foodApi
      .getFoods("/pages/slug/" + routeParams.id)
    console.log("cucu", cucu);
  }); */ //[routeParams.id]
  return (
    <>
      <div className="w-full h-screen relative overflow-hidden">
        {/* Background image */}
        <div className="h-full bg-[url('assets/background.webp')] bg-cover"></div>
        <div className="absolute top-0 w-full">
          <Navbar transparent />

          <div className="text-white">
            <div className="max-w-[800px] mt-[-46px] w-full h-screen mx-auto text-center flex flex-col justify-center">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                  <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                    {pag && (
                      <h1 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                        {pag[0].title}
                        <Meta title={pag[0].title} />
                      </h1>
                    )}
                    {/* <h2
                className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]"
              >
                Our Recent Projects
              </h2> 
              <p className="text-base text-body-color">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
  </p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* First section */}

      <div
        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
        style={{ height: "70px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto px-4 py-4 left-8 right-8">
        {pag===null && <Loading />}
        <div
          className="mx-4 flex flex-wrap"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-delay="400"
        >
          {pag && (
            <>
              <div className="ql-snow w-full">
                {" "}
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(pag[0].description),
                  }}
                />{" "}
              </div>
              {/*   <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pag[0].description),
              }}
            ></p> */}
            </>
          )}
        </div>
      </div>

      {/* Final section */}
    </>
  );
};

export default Pages;
