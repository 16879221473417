import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-[#000300]">
      <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-2 gap-8 text-gray-300">
        <div>
          <figure className="relative max-w-sm transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0">
            <a
              className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              href="/#"
            >
              <img
                src="logo192.png"
                align="left"
                className="rounded-lg w-12 h-12"
                alt="Star Soft Design"
              />

              <h1 className="w-full text-3xl font-bold text-[#00df9a] uppercase ">
                &nbsp;&nbsp;Star Soft Design
              </h1>
            </a>
          </figure>

          <p className="py-4">
            Considering your time and satisfaction as very important, we are at
            your disposal with complete services in the IT field: from web
            development, web design, web hosting, ISP to consultancy and
            specialized technical solutions
          </p>
          <div className="flex justify-between md:w-[75%] my-6">
            <a href="https://www.facebook.com/starsoftdesign" target="_blank">
              <FaFacebookSquare size={30} />{" "}
            </a>
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            <FaGithubSquare size={30} />
            <FaDribbbleSquare size={30} />
          </div>
        </div>
        <div className="lg:cols-span-2 flex justify-between mt-6">
          <div>
            <h6 className="font-medium text-gray-400">Solutions</h6>
            <ul>
              <li className="py-2 text-sm">Analytics</li>
              <li className="py-2 text-sm">Marketing</li>
              <li className="py-2 text-sm">Commerce</li>
              <li className="py-2 text-sm">Insights</li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">Support</h6>
            <ul>
              <li className="py-2 text-sm">Mentanace</li>
              <li className="py-2 text-sm">Documentation</li>
              <li className="py-2 text-sm">Issues Tracker</li>
              <li className="py-2 text-sm">Project Status</li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">Company</h6>
            <ul>
              <li className="py-2 text-sm">
                <a href="/#aboutus" className="nav-link">
                  About
                </a>
              </li>
              <li className="py-2 text-sm">
                <a href="/#services" className="nav-link">
                  Services
                </a>
              </li>
              <li className="py-2 text-sm">
                <a href="/projects" className="nav-link">
                  Projects{" "}
                </a>
              </li>
              <li className="py-2 text-sm">
                {" "}
                <a
                  href="https://support.starsoftdesign.ro/"
                  target="_blank"
                  className="nav-link"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">Legal</h6>
            <ul>
              <li className="py-2 text-sm">
                <a
                  href="/page/politica-de-confidentialitate"
                  className="nav-link"
                >
                  Policy
                </a>
              </li>
              <li className="py-2 text-sm">Terms</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
