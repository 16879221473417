
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";


const ProductCard = ({ id, image, title, description, price }) => {
  const location = useLocation();

  return (
  
    <div className="w-full px-4 md:w-1/2 xl:w-1/3" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="400" >
    <div className="relative mb-12">
             <div className="overflow-hidden rounded-lg w-full">
             <Link
          to={{
            pathname: `/product/${id}`,
            state: {
              background: location,
            },
          }}
        >
          <LazyLoadImage
            alt="card-img"
            src={process.env.REACT_APP_API_URL + "/products/image/" + image} 
            width="100%"
            style={{ minHeight: '10rem' }}
            placeholderSrc={image.placeholderSrc}
          />
        </Link>
             </div>
             <div
               className="relative z-10 mx-7 -mt-20 rounded-lg bg-white py-9 px-3 text-center shadow-lg"
             >
               <span className="mb-2 block text-xl font-bold  text-primary">
               {title}
               </span>
              {/*} <h3 className="mb-4 text-sm font-semibold text-dark">
               {description}
               </h3>*/}
               <div className="ql-snow w-full">
            {" "}
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
            />{" "}
          </div>
               <Link
          to={{
            pathname: `/product/${id}`,
            state: {
              background: location,
            },
          }}
          className="inline-block rounded-md border py-3 px-7 text-sm font-semibold text-body-color transition hover:border-primary hover:bg-blue-600 hover:text-white">
Price star from {price}
        </Link>
 
             </div>
           </div>
</div>
  );
};
export default ProductCard;
