import Modal from "./form/Modal";
import first from "../assets/single.png";
import second from "../assets/double.png";
import third from "../assets/triple.png";

const PaymentPlanCards = () => {
  return (
    <section id="products">
      <div className="w-full py-[10rem] px-4 bg-white">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-white"
              src={first}
              alt=""
            />
            <h2 className="text-2xl font-bold text-center py-8">
              Professional Presentation Website
            </h2>
            <p className="text-center text-4xl font-bold">from €219</p>
            <div className="text-center font-medium">
              <p className="border-b mx-8 py-2 mt-8">5-10 Pages</p>
              <p className="border-b mx-8 py-2">
                Website maintenance for 60 days
              </p>
              <p className="border-b mx-8 py-2">30 Changes</p>
            </div>
            <Modal tip={"Website Profesional de Prezentare"} />
          </div>
          <div className="w-full shadow-xl bg-gray-50 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-transparent"
              src={second}
              alt=""
            />
            <h2 className="text-2xl font-bold text-center py-8">
              Professional Blog-type Web
            </h2>
            <p className="text-center text-4xl font-bold">from €499</p>
            <div className="text-center font-medium">
              <p className="border-b mx-8 py-2 mt-8">
                Website administration 60 days
              </p>
              <p className="border-b mx-8 py-2">
                Website maintenance for 90 dayse
              </p>
              <p className="border-b mx-8 py-2">60 Changes</p>
            </div>
            <Modal tip={"Web Profesional de tip Blog"} />
          </div>
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-white"
              src={third}
              alt=""
            />
            <h2 className="text-2xl font-bold text-center py-8">
              Create Online Store
            </h2>
            <p className="text-center text-4xl font-bold">from €999</p>
            <div className="text-center font-medium">
              <p className="border-b mx-8 py-2 mt-8">
                Website administration 60 days
              </p>
              <p className="border-b mx-8 py-2">
                Website maintenance for 120 days
              </p>
              <p className="border-b mx-8 py-2">90 Changes</p>
            </div>
            <Modal tip={"Creare Magazin Online"} />
          </div>
        </div>
        <div className="text-center">
          <div className="text-center ">
            <Modal tip={"all"} />
            {"   "}
            <a href="/product">
              <button
                className="text-center bg-blue-600 w-[150px] uppercase font-bold rounded-md my-6 mx-auto py-3 
                    text-white shadow-lg shadow-gray-800 hover:scale-110 hover:shadow-2xl 
                    hover:shadow-black duration-300 active:scale-100"
                type="button"
              >
                View More &rarr;
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentPlanCards;
